<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Consumo de Materiais Detalhados
    </v-card-title>
    <master-detail
      :canDelete="false"
      :canEdit="false"
      :cols="colunas"
      hasBeneficioFilter
      :hasExportCSV="false"
      :hasMonthlyFilter="true"
      :hasNewButton="false"
      :opts="opts"
      :resourceUrl="resourceUrl"
      :serverPagination="true"
      @click="rowClick"
    >
      <calculation-status-menu
        beneficio
        class="table-v-action-button mr-3"
        icon="mdi-alpha-c-box-outline"
        name="Status de Consumo de Materiais Detalhados"
        type="materiais"
      ></calculation-status-menu>
    </master-detail>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "calculation-status-menu": () => import("@/components/calculation-status-menu.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    resourceUrl: function () {
      return `v1/fiscal/nfoperacional/${this.clientId}/detalhado`;
    },  
    colunas: function () {
      return [{
          key: "OP",
          name: "OP",
        },
        {
          key: "cod_produto",
          name: "Cód. Prod.",
        },
        {
          key: "descricao",
          name: "Descrição",
        },
        {
          key: "qtd_movimento",
          name: "Qtd. Moviment.",
          type: this.$fieldTypes.DOUBLE,
          align: 1,
        },
        {
          key: "vlr_unitario",
          name: "Vlr Unit.",
          type: this.$fieldTypes.MONEY,
          align: 1,
        },
        {
          key: "vlr_tot_movimento",
          name: "Vlr Total",
          type: this.$fieldTypes.MONEY,
          align: 1,
        },
        {
          key: "data_movimento",
          name: "Dt Movimento",
          type: this.$fieldTypes.DATE,
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
        },
        {
          key: "projeto",
          name: "Projeto",
          align: -1,
          width: '410px',
        },
        {
          key: "origem",
          name: "Origem",
          width: '190px',
        },
        {
          key: "unidade",
          name: "Unidade",
          align: -1,
          width: '410px',
        },
        {
          key: "classificacao",
          name: "Classificação",
          colSize: 6,
        },
        {
          key: "jobId",
          name: "ID Importação",
        },
      ];
    },
  },
  methods: {
    rowClick: function (item) {
      let routeData = this.$router.resolve({
        path: this.clientRoute(
          `/dispendio/servicoterceiros/fiscal/nft/${item.nfId}`
        ),
        query: {
          itemId: item.itemId,
          criterio_rateio: item.criterio_rateio_value,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  data: function () {
    return {
      opts: {
        criterio_rateio: {
          1: "Interno",
          2: "Terceiro",
        },
      },
    };
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>
